import { InjectionToken } from '@angular/core';

// import translations
import { LANG_EN_NAME, LANG_EN_TRANS } from './lang-en';
import { LANG_KH_NAME, LANG_KH_TRANS } from './lang-kh';
import { LANG_CN_NAME, LANG_CN_TRANS } from './lang-cn';
 
// translation token
export const TRANSLATIONS = new InjectionToken ('translations');

// all translations
export let dictionary = {
    [LANG_EN_NAME]: LANG_EN_TRANS,
    [LANG_KH_NAME]: LANG_KH_TRANS,
    [LANG_CN_NAME]: LANG_CN_TRANS,
};

// providers
export const TRANSLATION_PROVIDERS = [
    { provide: TRANSLATIONS, useValue: dictionary },
];