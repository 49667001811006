import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  mfgloc: string = environment.mfgloc;

  loginForm: FormGroup;
  loading = true;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthService) { 
      let userinfo = JSON.parse(localStorage.getItem(`${this.mfgloc}_User`));

      if (userinfo) {
        // logged in so return true
        this.router.navigate([userinfo['startup']]);
      }
    }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      userid: ['', Validators.required],
      password: ['', Validators.required],
      remember: [false]
    });

    // reset login status
    this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    
    this.loading = true;
    this.authenticationService.login(this.f.userid.value, this.f.password.value, this.f.remember.value)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate([data['startup']]);
        },
        error => {
          // console.log(error)
          this.error = error;
          this.loading = false;
        });
  }
}