import { Injectable, Inject, EventEmitter, Output } from '@angular/core';
import { TRANSLATIONS } from './translation'; // import our opaque token
import { environment } from '../../environments/environment';
import { TranslationService } from '../services/translate.service';
 
@Injectable()
export class TranslateService {
    private PLACEHOLDER = '%'; // our placeholder
    private mfgloc: string = environment.mfgloc;

    public get currentLang() {
        let userinfo = JSON.parse(localStorage.getItem(`${this.mfgloc}_User`));

        return userinfo['lang'] || userinfo['default-lang']; // return default lang if no current lang
    }
    
    public setDefaultLang(lang: string) {
        let userinfo = JSON.parse(localStorage.getItem(`${this.mfgloc}_User`));
        // console.log(userinfo);

        userinfo['default-lang'] = lang; // set default lang

        localStorage.setItem(`${this.mfgloc}_User`, JSON.stringify(userinfo));
    }
    
    public enableFallback(enable: boolean) {
        let userinfo = JSON.parse(localStorage.getItem(`${this.mfgloc}_User`));

        userinfo['lang-fallback'] = enable; // enable or disable fallback language
        localStorage.setItem(`${this.mfgloc}_User`, JSON.stringify(userinfo));
    }

    // public get currentLang() {
    //     return this._currentLang;
    // }

    // add event
    @Output() onLangChanged: EventEmitter<string> = new EventEmitter<string>();

    // inject our translations
    constructor(
        @Inject(TRANSLATIONS) private _translations: any,
        private _translate: TranslationService
        ) {

            this._translate.getKHTranslation().subscribe((res) => this._translations['kh'] = res[0]);
            this._translate.getCNTranslation().subscribe((res) => this._translations['cn'] = res[0]);
    }

    public use(lang: string): void {
        let userinfo = JSON.parse(localStorage.getItem(`${this.mfgloc}_User`));

        // set current language
        userinfo['lang'] = lang;

        // publish changes
        this.onLangChanged.emit(lang); 

        localStorage.setItem(`${this.mfgloc}_User`, JSON.stringify(userinfo));
    }

    private translate(key: string): string { // refactor our translate implementation
        let userinfo = JSON.parse(localStorage.getItem(`${this.mfgloc}_User`));
        let translation = key;

        // found in current language
        if (this._translations[this.currentLang] && this._translations[this.currentLang][key.toLowerCase()]) {
            return this._translations[this.currentLang][key.toLowerCase()];
        }
    
        // fallback disabled
        if (!userinfo['lang-fallback']) { 
            return translation;
        }
    
        // found in default language
        if (this._translations[userinfo['default-lang']] && this._translations[userinfo['default-lang']][key.toLowerCase()]) {
            return this._translations[userinfo['default-lang']][key.toLowerCase()];
        }
    
        // not found
        return translation;
    }
    
    // private translate(key: string): string {
    //     // private perform translation
    //     let translation = key;

    //     if (this._translations[this.currentLang] && this._translations[this.currentLang][key]) {
    //         return this._translations[this.currentLang][key];
    //     }

    //     return translation;
    // }

    // public instant(key: string) {
    //     // call translation
    //     return this.translate(key); 
    // }

    public instant(key: string, words?: string | string[]) { // add optional parameter
        const translation: string = this.translate(key);
    
        if (!words) return translation;
        return this.replace(translation, words); // call replace function
    }

    public replace(word: string = '', words: string | string[] = '') {
        let translation: string = word;

        const values: string[] = [].concat(words);
        values.forEach((e, i) => {
            translation = translation.replace(this.PLACEHOLDER.concat(<any>i), e);
        });

        return translation;
    }
}