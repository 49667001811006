import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { environment } from './../../environments/environment';

@Injectable({ 
  providedIn: 'root'
})
export class TranslationService {
  private mfgloc: string = environment.mfgloc;

  constructor(private http: HttpClient) { }
  
  getKHTranslation(): Observable<any> {
    const url = environment.api[`mfgloc`];
    const cmd = 'aCT.dbo.NGP_Translation';

    // console.log(this.url + cmd + p1);
    return this.http.get(url + cmd).pipe(map((response) => response));
  }

  getCNTranslation(): Observable<any> {
    const url = environment.api[`actdev`];
    const cmd = 'aCT.dbo.NGP_Translation';

    // console.log(this.url + cmd + p1);
    return this.http.get(url + cmd).pipe(map((response) => response));
  }
}
