import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../services/auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  template: ''
})
export class LogoutComponent {
  mfgloc: string = environment.mfgloc;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthService) { 
    }

  ngOnInit() {
    // reset login status
    this.authenticationService.logout();

    // return login
    this.router.navigate(['/login']);
  }
}