import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NavService {
  mfgloc: string = environment.mfgloc;
  url = environment.api[this.mfgloc];

  constructor(private http: HttpClient) { }

  getNav(): Observable<any> {
    const userInfo = JSON.parse(localStorage.getItem(`${this.mfgloc}_User`));
    const cmd = 'aCT.dbo.NGP_GetNav';
    const p1 = '&p1=@userID&v1=' + userInfo['userID'];

    // console.log(this.url + cmd + p1);
    return this.http.get(this.url + cmd + p1).pipe(map((response) => response));
  }
}
