import { Component, Input } from '@angular/core';
import { navItems } from './../../_nav';
import { NavService } from '../../services/nav.service';
import { environment } from '../../../environments/environment';

//translate
// import { TranslateService } from '../../translate/translate.service';
import { SharedLangImitService } from '../../shared-langemit.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent {
  mfgloc: string = environment.mfgloc;

  private _navItems;
  public navItems;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement = document.body;

  //translation
  public translatedText: string;
  public supportedLanguages: any[];
  public _currLangIcon: string;
  public _currLangDisplay: string;

  constructor(
    private navservice: NavService,
    // private _translate: TranslateService,
    private _sharedLang: SharedLangImitService
  ) {
    // let userinfo = JSON.parse(localStorage.getItem(`${this.mfgloc}_User`));
    // console.log(userinfo);
    // this.navservice.getNav().subscribe(res => {
    //   console.log(res);

    //   let _paths = res.map(e => e[Object.keys(e)[3]]).filter(Boolean);;

    //   this._navItems = res;

    //   //update auth pages
    //   _paths.push = userinfo['startup'];
    //   userinfo['AuthPages'] = _paths;
    //   localStorage.setItem(`${this.mfgloc}_User`, JSON.stringify(userinfo));

    //   this.navItems = this.toNested(this._navItems);
    // });

    this.navItems = navItems;

    // console.log(this.navItems);
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });

    this.changes.observe(<Element>this.element, {
      attributes: true
    });
   
  }

  ngOnInit() {
    
    // let userinfo = JSON.parse(localStorage.getItem(`${this.mfgloc}_User`));
    let element: HTMLElement = document.body;
    element.setAttribute('style','overflow: auto;') 

    // if (userinfo.AuthPages[0].endsWith('istv')) {
    //   let elemNavBar = element.getElementsByTagName('app-sidebar')[0];
    //   let elemMain = element.getElementsByTagName('main')[0];
    //   let elemFooter = element.getElementsByTagName('footer')[0];
    //   let elemAppBody = element.getElementsByClassName('app-body')[0];
    //   let elemHeader = element.getElementsByTagName('header')[0];

    //   elemHeader.setAttribute('style','display: flex;')         
    //   elemAppBody.setAttribute('style','overflow: hidden;')      
    //   elemNavBar.setAttribute('style','margin-left: -200px;')
    //   elemMain.setAttribute('style','margin-left: 0px;')
    //   elemFooter.setAttribute('style','margin-left: 0px; display: flex')
    // }

    // standing data
    this.supportedLanguages = [
      { display: 'English', value: 'en', icon: 'flag-icon-us' },
      // { display: 'Khmer', value: 'kh', icon: 'flag-icon-kh' },
      // { display: 'Chinese', value: 'cn', icon: 'flag-icon-cn' },
    ];
       
    // this.subscribeToLangChanged(); // subscribe to language changes
    // console.log(userinfo);
    this._sharedLang.cast.subscribe(lang => {
      // console.log(lang);
    });

    // set language
    // this._translate.setDefaultLang('en'); // set English as default
    // this._translate.enableFallback(true); // enable fallback

    // set current langage
    // this.selectLang(userinfo['lang'] || userinfo['default-lang']);
  }

  getCurrentLang() {
    // let _currentLangInfo = this.supportedLanguages.filter(search => search['value'] == this._translate.currentLang)[0];

    // this._currLangIcon = _currentLangInfo['icon'];
    // this._currLangDisplay = _currentLangInfo['display'];
  }

  selectLang(lang: string) {
    // set current lang;
    // this._translate.use(lang);
    this.getCurrentLang();
    this._sharedLang.updateLang(lang);
    // this.refreshText();
  }

  subscribeToLangChanged() {
    // refresh text
    // please unsubribe during destroy
    // return this._translate.onLangChanged.subscribe(x => this.refreshNavItems());
  }

  refreshNavItems() {
    let tmpNavItems;

    // console.log('changed')

    // refresh nav items when language change
    // if (this._navItems) {
    //   tmpNavItems = JSON.parse(JSON.stringify(this._navItems));

    //   // tmpNavItems.forEach(element => {
    //   //   element['name'] = this._translate.instant(element['name']);

    //   //   console.log(element['name'], this._translate.instant(element['name']))
    //   // });

    //   this.navItems = [];
    //   this.navItems = this.toNested(tmpNavItems);
    //   console.log(this.navItems)
    // }
    // this.translatedText = this._translate.instant('Report & Analysis');
  }

  toNested(_arr: Array<object>) {
    let map = {};
    let _nav: Array<Object>;

    for (let i = 0; i < _arr.length; i++) {
      let obj = _arr[i];
      obj['children'] = [];

      if (obj['title'] == 1) obj['title'] = true;

      map[obj['CODE']] = obj;

      let parent = obj['parent'] || '-';
      if (!map[parent]) {
        map[parent] = {
          children: []
        };
      }

      delete obj['CODE'];
      delete obj['PAGESEQ'];
      delete obj['PAGESEQ'];
      delete obj['PAGESEQ'];
      delete obj['SEQ'];

      // obj['name'] = this._translate.instant(obj['name']);

      map[parent].children.push(obj);
    }

    const res = JSON.parse(JSON.stringify(map), (k, v) => {
      return (v === null // delete null values
        || (Array.isArray(v) && v.length === 0) // delete empty arrays
        || (typeof v === 'object' && Object.keys(v).length === 0)) // delete empty objects
        ? undefined : v // else return the value
    });

    _nav = res['-'].children;
    return _nav;
  }
}
