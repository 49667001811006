import { NgModule, ModuleWithProviders } from '@angular/core';
// import { CommonModule } from '@angular/common';

//translations
import { TRANSLATION_PROVIDERS }   from './translate/translation';
import { TranslatePipe }   from './translate/translate.pipe';
import { TranslateService }   from './translate/translate.service';

@NgModule({
  declarations: [
    TranslatePipe
  ], 
  exports: [
    TranslatePipe
  ]
})
export class SharedTranslatePipeModule { 
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedTranslatePipeModule,
      providers: [ 
        TRANSLATION_PROVIDERS,
        TranslateService
       ]
    };
  }
}
