import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterEvent, NavigationEnd, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { AuthService } from '../services/auth.service';
import { NavService } from '../services/nav.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  mfgloc: string = environment.mfgloc;
  
  previousUrl: string;
  currentUrl: string;

  constructor(
    private router: Router, 
    private auth: AuthService,
    private navservice: NavService) { 
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {   
          this.previousUrl = event.url;
        };
      });
    }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let userInfo = JSON.parse(localStorage.getItem(`${this.mfgloc}_User`));
    console.log(userInfo,state.url);
    let isTV = false;
    let sysToken;
    
    if (state.url.endsWith('istv')) {
      isTV = true;
      sysToken = {
        "userID": "dash1",
        "password": null,
        "rememberMe": false,
        "isActive": true,
        "userName": null,
        "startup": state.url,
        "lang": "en",
        "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImRhc2gxIiwibmJmIjoxNTUyOTg3OTY4LCJleHAiOjE4Njg2MDcxNjgsImlhdCI6MTU1Mjk4Nzk2OH0.kQclnH-p4mG5sjwwHGZegVao1OIpTbd5Ytcn9jPLrnQ"
      }

      sysToken['AuthPages'] = [sysToken['startup']];

      // this.navservice.getNav().subscribe(res => {
      //   let _paths = res.map(e => e[Object.keys(e)[3]]).filter(Boolean);;
  
      //   //update auth pages
      //   sysToken['AuthPages'] = _paths;
  
      // });
      
      userInfo = sysToken;
      console.log(userInfo);
      localStorage.setItem(`${this.mfgloc}_User`, JSON.stringify(sysToken));
    }
    else if (state.url.includes('route')) {
      userInfo['AuthPages'].push(state.url);
    }
    
    if (userInfo) {
      //unauthorized page access
      if (userInfo['AuthPages'].indexOf(state.url) < 0) {       
        this.router.navigate(['/401']);
        return false;
      }
      // console.log(state.url)
      // console.log(this.previousUrl);

      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
