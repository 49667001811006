import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  mfgloc: string = environment.mfgloc;
  url = environment.api[`auth_${this.mfgloc}`];

  constructor(private http: HttpClient) { }

  login(username: string, password: string, remember: boolean): Observable<any> {
    return this.http.post(this.url, { userid: username, password: password, rememberme: remember })
          .pipe(map(user => {
              // login successful if there's a jwt token in the response
              if (user && user['token']) {
                  // store user details and jwt token in local storage to keep user logged in between page refreshes
                  user['AuthPages'] = [user['startup']];
                  localStorage.setItem(`${this.mfgloc}_User`, JSON.stringify(user));
              }

              return user;
          }));
  }

  getToken(): string {
    const currentUser = JSON.parse(localStorage.getItem(`${this.mfgloc}_User`));
    let _token = '';

    if (currentUser && currentUser.token) {
        _token = currentUser.token;
    }

    return _token;
  }

  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem(`${this.mfgloc}_User`);
  }
}
