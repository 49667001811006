import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable()
export class SharedLangImitService {
    private lang = new BehaviorSubject<string>('en');
    cast = this.lang.asObservable();

    constructor () { } 

    updateLang(_lang: string) {
      console.log(`updateLang ${_lang}`);
      this.lang.next(_lang);
    }
    
}